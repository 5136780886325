import io from 'socket.io-client';
import feathers, { socketio, authentication } from '@feathersjs/client';
import { rx } from 'feathers-reactive';

export default (url) => {
  const options = { transports: ['websocket'], timeout: 20000 };
  const socket = io(url, options);
  const app = feathers()
    .configure(socketio(socket, { timeout: 120000 }))
    .configure(rx({ idField: 'id' }))
    .configure(authentication({ storage: localStorage }));

  app.socket = socket;

  return app;
};
