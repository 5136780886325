import { computed, observable, reaction } from 'mobx';
import moment from 'moment';
import numeral from 'numeral';
import mutateMetrics from '@/containers/metrics/mutate-metrics';

const FORMAT = 'YYYY-MM-DD';


const getPercentageChange = (a, b) => {
  if (numeral(a).value() === 0) {
    return 100;
  }
  const result = numeral(a).subtract(b).divide(a).multiply(100).multiply(-1);
  return parseFloat(result.value().toFixed(2));
};

export default class MetricsStore {
  @observable previous = null;
  @observable current = null;
  @observable isLoading = false;
  @observable metrics = null;

  @computed get difference () {
    return {
      totalTransactionValue: this.current.totalTransactionValue.map(currency => {
        const previous = this.previous.totalTransactionValue.find(entry => entry.name === currency.name);

        return previous ? {
          name: currency.name,
          previous: previous.amount,
          diff: getPercentageChange(previous.amount, currency.amount)
        } : null;
      }),
      totalCompletedOrders: {
        previous: this.previous.totalCompletedOrders,
        diff: getPercentageChange(this.previous.totalCompletedOrders, this.current.totalCompletedOrders)
      },
      averageConversionRate: {
        previous: this.previous.averageConversionRate,
        diff: getPercentageChange(this.previous.averageConversionRate, this.current.averageConversionRate)
      },
      totalCustomers: {
        previous: this.previous.totalCustomers,
        diff: getPercentageChange(this.previous.totalCustomers, this.current.totalCustomers)
      },
      totalUsers: {
        previous: this.previous.totalUsers,
        diff: getPercentageChange(this.previous.totalUsers, this.current.totalUsers)
      },
      averageOrderCountByCustomer: {
        previous: this.previous.averageOrderCountByCustomer,
        diff: getPercentageChange(this.previous.averageOrderCountByCustomer, this.current.averageOrderCountByCustomer)
      }
    }
  }

  constructor ({ app, client, uiStore }) {
    this.app = app;
    this.client = client;
    this.uiStore = uiStore;

    reaction(() => app.isAuthenticated, () => {
      if (app.isAuthenticated) {
        this.getMetrics();
      }
    });
  }

  getMetrics = async () => {
    this.isLoading = true;

    const { preset, query } = this.uiStore.metrics;
    const previousQuery = { period: query.period };
    const start = moment(query.start, FORMAT);
    const end = moment(query.end, FORMAT);
    const result = await this.client.service('metrics').find({ query });

    this.metrics = result;
    this.previous = null;
    this.current = Object.assign({}, mutateMetrics(result));
    this.isLoading = false;

    switch (preset) {
      case 'From start of month':
        previousQuery.start = start.subtract(1, 'month').startOf('month').format(FORMAT);
        previousQuery.end = end.subtract(1, 'month').format(FORMAT);
        break;
      case 'From start of quarter':
        previousQuery.start = start.subtract(1, 'quarter').startOf('quarter').format(FORMAT);
        previousQuery.end = end.subtract(1, 'quarter').format(FORMAT);
        break;
      case 'Last 30 days':
        previousQuery.start = start.subtract(30, 'days').format(FORMAT);
        previousQuery.end = end.subtract(30, 'days').format(FORMAT);
        break;
      case 'From start of year':
        previousQuery.start = start.subtract(1, 'year').startOf('year').format(FORMAT);
        previousQuery.end = end.subtract(1, 'year').format(FORMAT);
        break;
      default:
    }

    if (start.isValid() && end.isValid()) {
      this.previous = Object.assign({}, mutateMetrics(await this.client.service('metrics').find({ query: previousQuery })));
    }
  }
}
