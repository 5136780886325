import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Icon, Menu as AntMenu } from 'antd';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 4.5em;
  height: 100%;
`;

const Menu = styled(AntMenu)`
  background: none;
  border-color: rgba(0, 0, 0, .025);
  flex-grow: 1;
  font-size: 1.125em;
  height: 100%;
  padding: 1em 0;

  .ant-menu-item {
    align-items: center;
    display: flex;
    font-size: 1em;

    .anticon {
      font-size: 1.125em;
      margin-right: 1em;
    }

    &-selected {
      background: none !important;

      &::after {
        display: none;
      }
    }
  }
`;

const Toggle = styled.button`
  background: none;
  border: 1px solid rgba(0, 0, 0, .025);;
  border-left: none;
  border-bottom: none;
  cursor: pointer;
  font-size: 1.125em;
  outline: none;
  overflow: hidden;
  padding: ${props => props.$collapsed ? '1em 2em' : '1em 1.5em'};
  text-align: left;
  transition: padding 250ms;
  white-space: nowrap;
  position: sticky;
  bottom: 0;

  i {
    margin-left: .125em;
    margin-right: 1.25em;
  }

  span {
    transition: opacity 250ms;
    opacity: ${props => props.$collapsed ? 0 : 1};
    will-change: opacity;
  }
`;

const AppMenu = ({ collapsed, location, onClick, onToggle }) => {
  const [selectedKey, setSelectedKey] = useState(location.pathname.split('/').pop());

  const items = [{
    key: 'metrics',
    icon: 'bar-chart',
    label: 'Metrics'
  }, {
    key: 'brands',
    icon: 'star',
    label: 'Brands'
  }, {
    key: 'orders',
    icon: 'shopping-cart',
    label: 'Orders'
  }, {
    key: 'balances',
    icon: 'dollar',
    label: 'Balances'
  }, {
    key: 'categories',
    icon: 'folder',
    label: 'Categories'
  }, {
    key: 'countries',
    icon: 'global',
    label: 'Countries'
  }, {
    key: 'customers',
    icon: 'user',
    label: 'Customers'
  }, {
    key: 'referrers',
    icon: 'team',
    label: 'Referrers'
  }];

  useEffect(() => {
    if (location.pathname.includes('orders/')) {
      setSelectedKey('orders');
    } else if (location.pathname.includes('customers/')) {
      setSelectedKey('customers');
    } else {
      setSelectedKey(location.pathname.split('/').pop());
    }
  }, [ location ]);

  return (
    <Container>
      <Menu
        onClick={onClick}
        mode="inline"
        selectedKeys={[ selectedKey ]}>
        {items.map(item => (
          <AntMenu.Item key={item.key}>
            <Icon type={item.icon} />
            <span>{item.label}</span>
          </AntMenu.Item>
        ))}
      </Menu>
      <Toggle onClick={onToggle} $collapsed={collapsed}>
        <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
        <span>Toggle Sidebar</span>
      </Toggle>
    </Container>
  );
};

export default observer(withRouter(AppMenu));
