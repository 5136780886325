const shadows = {
  secondary: '0 .0625em .125em rgba(0, 0, 0, .125), 0 .25em 1em rgba(75, 77, 241, .1)',
  primary: '0 .125em .25em rgba(0, 0, 0, .125), 0 1em 2em rgba(75, 77, 241, .1)',
  hover: '0 .25em .5em rgba(0, 0, 0, .125), 0 1em 2em rgba(75, 77, 241, .15)',
  inset: {
    top: 'inset 0 .25em .25em -.25em rgba(0, 0, 0, .125), inset 0 1em 1em -1em rgba(75, 77, 241, .1)'
  }
};

const materialColors = {
  red: '#F44336',
  pink: '#EC407A',
  purple: '#C088DA',
  deepPurple: '#9575CD',
  indigo: '#5C6BC0',
  blue: '#42A5F5',
  lightBlue: '#4FC3F7',
  cyan: '#26C6DA',
  teal: '#26A69A',
  green: '#66BB6A',
  lightGreen: '#83DEAD',
  lime: '#D4E157',
  yellow: '#FFEB3B',
  amber: '#FFCA28',
  orange: '#FFA726',
  deepOrange: '#FF7043',
  brown: '#8D6E63',
  grey: '#9E9E9E',
  blueGrey: '#607D8B',
  darkBluegrey: '#37474F',
  lightGrey: '#ECEFF1',
  white: '#FFFFFF',
  transparent: 'rgba(0, 0, 0, 0)'
};

const colors = Object.assign({}, materialColors, {
  background: {
    primary: '#FAFBFF',
    inverted: materialColors.darkBluegrey
  },
  brand: {
    primary: '#4B4DF1',
    secondary: materialColors.indigo,
    accent: '#E4517F'
  },
  status: {
    danger: materialColors.red,
    warning: materialColors.amber,
    info: materialColors.blue,
    success: materialColors.lightGreen
  },
  typography: {
    primary: materialColors.darkBluegrey,
    inverted: materialColors.lightGrey,
    secondary: `${materialColors.darkBluegrey}66`,
    lighter: `${materialColors.darkBluegrey}11`
  }
});

const breakpoints = {
  xs: 0,
  sm: 615,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 3000
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = `@media (max-width: ${breakpoints[label]}px)`;
  return acc;
}, {});

module.exports = { breakpoints, colors, materialColors, media, shadows };
