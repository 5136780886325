import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ConditionalRoute = ({ component, condition, redirectTo, ...rest }) => {
  if (condition || condition === undefined) {
    return <Route component={component} {...rest} />
  }

  return <Redirect to={redirectTo} />
};

export default ConditionalRoute;
