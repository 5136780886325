import React, { useState } from 'react';
import styled from 'styled-components';
import { Alert, Button, Form, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { colors, media, shadows } from '@/theme';

const Container = styled.div`
  align-items: center;
  background: ${colors.brand.primary};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Content = styled(Form)`
  background: white;
  border-radius: .25em;
  box-shadow: ${shadows.primary};
  display: flex;
  flex-direction: column;
  padding: 2em;
  width: 30vw;

  ${media.lg} {
    width: 50vw;
  }

  ${media.md} {
    width: 66vw;
  }

  ${media.sm} {
    width: 100%;
  }
`;

const Submit = styled(Button)`
  margin-top: 1em;
`;

const Login = ({ app, form, history }) => {
  const [hasError, setHasError] = useState(false);
  const { getFieldDecorator } = form;

  const signIn = async () => {
    setHasError(false);

    try {
      await app.signIn(await form.validateFields());
      history.push('/metrics');
    } catch (e) {
      setHasError(true);
    }
  };

  return (
    <Container>
      <Content onSubmit={signIn}>
        <h1>Login</h1>
        {app.signInStatus?.error && <Alert type="error" message={app.signInStatus.error.message} />}
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Email is invalid' }
            ]
          })(<Input onPressEnter={signIn} size="large" type="email" placeholder="Email" />)}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Password is required' }
            ]
          })(<Input.Password onPressEnter={signIn} size="large" placeholder="Password" />)}
        </Form.Item>
        <Submit
          loading={app.signInStatus?.isLoading}
          size="large"
          type="primary"
          onClick={signIn}>
          Login
        </Submit>
      </Content>
    </Container>
  );
};

export default inject('app')(Form.create()(observer(withRouter(Login))));
