import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import loadable from '@loadable/component';

import ConditionalRoute from '@/components/conditional-route';

const Router = ({ app }) => {
  return (
    <Fragment>
      <ConditionalRoute
        component={loadable(() => import('./containers/metrics'))}
        path="/metrics"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/brands'))}
        exact
        path="/brands"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/categories'))}
        path="/categories"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/customers'))}
        exact
        path="/customers"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/customer'))}
        exact
        path="/customers/:id"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/balances'))}
        path="/balances"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/countries'))}
        path="/countries"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/orders'))}
        path="/orders"
        exact
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/order'))}
        exact
        path="/orders/:id"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/brand'))}
        exact
        path="/brands/:id"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/referrers'))}
        exact
        path="/referrers"
        redirectTo="/login"
        condition={app.isAuthenticated} />
      <ConditionalRoute
        component={loadable(() => import('./containers/referrers/referrer-details'))}
        path="/referrers/:id"
        redirectTo="/login"
        condition={app.isAuthenticated} />
    </Fragment>
  );
};

export default inject('app')(Router);
