import groupBy from 'lodash.groupby';
import omit from 'lodash.omit';
import numeral from 'numeral';
import moment from 'moment';
import { countBy, sumBy, uniqBy } from 'lodash';
import CURRENCIES from '@/currencies.json';

const FORMAT = 'YYYY-MM-DD';

const mergeByCurrency = (data = [], source) => {
  const allCurrencies = [];
  const formatted = data.map(item => {
    if (!allCurrencies.includes(item.currency)) {
      allCurrencies.push(item.currency);
    }

    return {
      date: item.date,
      [item.currency]: item[source]
    }
  });

  const grouped = groupBy(formatted, i => i.date);
  const formattedData = Object.keys(grouped).map(date => (
    grouped[date].reduce((acc, curr) => Object.assign(acc, curr), {})
  )).map(item => ({
    date: moment.utc(item.date).format(FORMAT),
    ...omit(item, 'date')
  }));

  formattedData.forEach(entry => {
    allCurrencies.map(currency => {
      if (typeof entry[currency] === 'undefined') {
        entry[currency] = 0;
      }
    });
  });

  return formattedData;
};

const sumTotals = (array = [], property, value) => {
  const other = { name: 'other', value: 0, currencies: [] };
  const totals = array.reduce((acc, curr) => {
    const v = numeral(acc[curr[property]] || 0).add(curr[value]).value();
    acc[curr[property]] = v;
    return acc;
  }, {});
  const totalValue = Object.keys(totals)
    .map(name => totals[name])
    .reduce((acc, curr) => acc + curr, 0);

  Object.keys(totals).forEach((name, index) => {
    const value = totals[name];
    const percentage = parseFloat(((value / totalValue) * 100).toFixed(2));

    if (percentage < 2) {
      other.value = other.value + value;
      other.currencies.push({
        name,
        value,
        percentage,
        index
      });
    }
  });

  other.currencies.map((currency) => {
    delete totals[currency.name];
  });

  return [
    ...Object.keys(totals).map(name => ({
      name,
      value: totals[name]
    })),
    other
  ];
};

const worldMapFormat = (array = [], value) => {
  const byCountry = groupBy(array, i => i.country);

  return Object.keys(byCountry).map(country => ({
    country,
    [value]: byCountry[country].reduce((acc, curr) => {
      return numeral((curr[value] || 0)).add(acc).value();
    }, 0)
  }));
};

const valueByProp = (array = [], property, value, extra) => {
  const byCurrency = groupBy(array, i => i.currency);

  Object.keys(byCurrency).forEach(currency => {
    const items = byCurrency[currency];
    byCurrency[currency] = groupBy(items, i => i.date);
    byCurrency[currency] = Object.keys(byCurrency[currency])
      .map(date => {
        return {
          date: moment.utc(date).format(FORMAT),
          ...byCurrency[currency][date].reduce((acc, curr) => {
            if (extra) {
              acc[curr[property]] = {
                value: curr[value],
                extra: curr[extra]
              };
            } else {
              acc[curr[property]] = curr[value];
            }
            return acc;
          }, {})
        }
      });
  });

  return byCurrency;
};

const ordersByProp = (array = [], property, value) => {
  const grouped = groupBy(array, o => o.date);

  return Object.keys(grouped).map(date => {
    return {
      date: moment.utc(date).format(FORMAT),
      ...grouped[date].reduce((acc, curr) => {
        acc[curr[property]] = curr[value];
        return acc;
      }, {})
    }
  });
};

export default metrics => {
  if (!metrics) return null;

  const mutated = {
    get customers () {
      const allCustomers = [
        ...metrics.newCustomers.map(c => ({ ...omit(c, 'count'), new: c.count })),
        ...metrics.payingCustomers.map(c => ({ ...omit(c, 'count'), paying: c.count })),
        ...metrics.customersAttemptedToBuy.map(c => ({ ...omit(c, 'count'), attemptedToBuy: c.count }))
      ];
      const byDate = groupBy(allCustomers, c => c.date);

      return Object.keys(byDate).map(date => ({
        date: moment.utc(date).format(FORMAT),
        ...byDate[date].reduce((acc, curr) => {
          Object.keys(omit(curr, 'date')).forEach(key => {
            acc[key] = curr[key];
          });
          return acc;
        }, {})
      }));
    },

    get newVsReturning () {
      const newVsReturning = [...metrics.returningCustomersCount, ...metrics.newPayingCustomersCount];
      const byDate = groupBy(newVsReturning, c => c.date);

      return Object.keys(byDate).map(date => ({
        date,
        ...byDate[date].reduce((acc, curr) => {
          Object.keys(omit(curr, 'date')).forEach(key => {
            acc[key] = curr[key];
          });
          return acc;
        }, {})
      }));
    },

    get totalNewVsReturning () {
      const sum = {};

      this.newVsReturning.forEach(entry => {
        Object.keys(omit(entry, 'date')).forEach(type => {
          sum[type] = (sum[type] || 0) + entry[type]
        });
      });

      return Object.keys(sum).map(type => ({
        name: type,
        count: sum[type]
      }));
    },

    get totalCustomersByType () {
      const sum = {};

      this.customers.forEach(entry => {
        Object.keys(omit(entry, 'date', 'new', 'paying')).forEach(type => {
          sum[type] = numeral(sum[type] || 0).add(entry[type]).value();
        });
      });

      sum.paying = this.totalNewVsReturning.reduce((acc, curr) => {
        return numeral(acc).add(curr.count || 0).value();
      }, 0);

      return Object.keys(sum).map(type => ({
        name: type,
        count: sum[type]
      }));
    },

    get totalCustomers () {
      return this.totalCustomersByType
        .filter(customer => customer.name === 'paying')
        .reduce((acc, key) => {
          return numeral(acc).add(key.count).value() || 0;
        }, 0);
    },

    get totalAttemptedCustomers () {
      return this.totalCustomersByType
        .filter(customer => customer.name === 'attemptedToBuy')
        .reduce((acc, key) => {
          return numeral(acc).add(key.count).value() || 0;
        }, 0);
    },

    get totalUsers () {
      return this.totalAttemptedCustomers + this.totalCustomers;
    },

    get spendByCustomer () {
      return mergeByCurrency(metrics.averageOrderSizeByCustomer, 'averageAmount');
    },

    get averageSpendByCustomer () {
      const byCurrency = groupBy(metrics.averageOrderSizeByCustomer, o => o.currency);
      const totals = metrics.averageOrderSizeByCustomer.reduce((acc, curr) => {
        const value = (acc[curr.currency] || 0) + curr.averageAmount;
        acc[curr.currency] = value;
        return acc;
      }, {});

      Object.keys(totals).forEach(currency => {
        totals[currency] = byCurrency[currency].length >= 1
          ? parseFloat(numeral(totals[currency])
            .divide(byCurrency[currency].length)
            .value()
            .toFixed(2)
          )
          : 0;
      });

      return totals;
    },

    get orderCountByCustomer () {
      return metrics.averageOrderCountByCustomer.map(item => ({
        date: moment.utc(item.date).format(FORMAT),
        ...omit(item, 'date')
      }));
    },

    get averageOrderCountByCustomer () {
      const total = this.orderCountByCustomer.reduce((acc, curr) => acc + curr.average, 0);
      return this.orderCountByCustomer.length
        ? numeral(total).divide(this.orderCountByCustomer.length).format('0,0[.]00')
        : '0';
    },

    get transactionValue () {
      return mergeByCurrency(metrics.transactionValue, 'total');
    },

    get totalTransactionValue () {
      const totals = metrics.transactionValue.reduce((acc, curr) => {
        const value = numeral((acc[curr.currency] || 0)).add(curr.total).value();
        acc[curr.currency] = value;
        return acc;
      }, {});

      return Object.keys(totals).map(currency => ({
        name: currency,
        amount: totals[currency]
      }));
    },

    get averageSalesPerPeriod () {
      const countByCurrency = countBy(metrics.transactionValue, o => o.currency);
      const totals = metrics.transactionValue.reduce((acc, curr) => {
        const value = (acc[curr.currency] || 0) + curr.total;
        acc[curr.currency] = value;
        return acc;
      }, {});

      Object.keys(totals).forEach(currency => {
        totals[currency] = parseFloat(numeral(totals[currency])
          .divide(countByCurrency[currency])
          .value()
          .toFixed(2)
        );
      });

      return totals;
    },

    get completedOrders () {
      return metrics.completedOrders.map(item => ({
        date: moment.utc(item.date).format(FORMAT),
        orders: numeral(item.orders).value()
      }));
    },

    get ordersByCurrency () {
      return mergeByCurrency(metrics.ordersByCurrency, 'orders');
    },

    get totalOrdersByCurrency () {
      return sumTotals(metrics.ordersByCurrency, 'currency', 'orders');
    },

    get spendByCurrency () {
      return mergeByCurrency(metrics.ordersByCurrency, 'spend');
    },

    get spendByNetwork () {
      const networks = uniqBy(CURRENCIES, c => c.servicePath)
        .map(c => ({ network: c.servicePath }))
        .reduce((acc, curr) => {
          acc[curr.network] = 0;
          return acc;
        }, {});

      const byNetwork = this.spendByCurrency.map(entry => {
        const formatted = { ...networks, date: entry.date };

        Object.keys(entry).forEach(protocol => {
          const currency = CURRENCIES.find(c => c.protocol === protocol);
          const value = entry[protocol];

          if (currency) {
            formatted[currency.servicePath] = formatted[currency.servicePath] + value;
          }
        });

        return formatted;
      });

      return byNetwork;
    },

    get totalSpendByNetwork () {
      const networks = uniqBy(CURRENCIES, c => c.servicePath)
        .map(c => ({
          name: c.servicePath,
          value: 0
        }));

      this.spendByNetwork.forEach(entry => {
        Object.keys(entry).forEach(network => {
          const value = entry[network];
          const index = networks.findIndex(n => n.name === network);

          if (index >= 0) {
            networks[index].value = networks[index].value + value;
          }
        });
      });

      return networks.filter(({ value }) => value > 0);
    },

    get totalSpendByCurrency () {
      return sumTotals(metrics.ordersByCurrency, 'currency', 'spend');
    },

    get totalCompletedOrders () {
      return metrics.completedOrders
        .reduce((acc, curr) => numeral(acc).add(curr.orders).value(), 0);
    },

    get averageCompletedOrders () {
      const totalRate = this.completedOrders.reduce((acc, curr) => numeral(acc).add(curr.orders).value(), 0);

      return this.completedOrders.length
        ? parseFloat(
          numeral(totalRate)
            .divide(this.completedOrders.length)
            .value()
            .toFixed(2)
        )
        : 0;
    },

    get conversionRate () {
      return metrics.conversionRate.map(rate => ({
        ...rate,
        date: moment.utc(rate.date).format(FORMAT)
      }));
    },

    get averageConversionRate () {
      const totalRate = this.conversionRate.reduce((acc, curr) => acc + curr.rate, 0);

      return this.conversionRate.length
        ? parseFloat(
          numeral(totalRate)
            .divide(this.conversionRate.length)
            .value()
            .toFixed(2)
        )
        : 0;
    },

    get topBrands () {
      return groupBy(metrics.topBrands, brand => brand.currency);
    },

    get topSpenders () {
      return groupBy(metrics.topSpenders, spender => spender.currency);
    },

    get ordersBySource () {
      return ordersByProp(metrics.ordersBySource, 'purchasedFrom', 'orders');
    },

    get totalOrdersBySource () {
      const totals = metrics.ordersBySource.reduce((acc, curr) => {
        const value = numeral(acc[curr.purchasedFrom] || 0).add(curr.orders);
        acc[curr.purchasedFrom] = value.value();
        return acc;
      }, {});

      return Object.keys(totals).map(source => ({
        name: source,
        orders: totals[source]
      }));
    },

    get valueBySource () {
      return valueByProp(metrics.transactionValueBySource, 'purchasedFrom', 'amount');
    },

    get totalValueBySource () {
      const sources = {};
      const totalValueBySource = {};

      Object.keys(this.valueBySource).forEach(currency => {
        sources[currency] = {};
        this.valueBySource[currency].forEach(item => {
          Object.keys(omit(item, 'date')).forEach(source => {
            const value = numeral(sources[currency][source] || 0).add(item[source]);
            sources[currency][source] = value.value();
          });
        });
        totalValueBySource[currency] = Object.keys(sources[currency]).map(source => {
          return ({ name: source, amount: sources[currency][source] });
        });
      });

      return totalValueBySource;
    },

    get valueByCountry () {
      if (!metrics.transactionValueByCountry) return [];
      return valueByProp(metrics.transactionValueByCountry, 'country', 'amount', 'name')
    },

    get totalValueByCountry () {
      const country = {};
      const totalValueByCountry = {};

      Object.keys(this.valueByCountry).forEach(currency => {
        country[currency] = {};
        this.valueByCountry[currency].forEach(item => {
          Object.keys(omit(item, 'date')).forEach(source => {
            const value = numeral(country[currency][source] ? country[currency][source].value : 0).add(item[source].value).value();
            country[currency][source] = {
              value,
              name: item[source].extra
            };
          });
        });
        totalValueByCountry[currency] = Object.keys(country[currency]).map(source => {
          return ({
            name: source,
            amount: country[currency][source]
          });
        });
      });

      return totalValueByCountry;
    },

    get mapValueByCountry () {
      if (!metrics.transactionValueByCountry) return [];
      return worldMapFormat(metrics.transactionValueByCountry, 'amount');
    },

    get ordersByCountry () {
      if (!metrics.ordersByCountry) return [];
      return ordersByProp(metrics.ordersByCountry, 'country', 'orders');
    },

    get totalOrdersByCountry () {
      if (!metrics.ordersByCountry) return [];
      const totals = metrics.ordersByCountry.reduce((acc, curr) => {
        const value = numeral(acc[curr.country] ? acc[curr.country].value : 0).add(numeral(curr.orders).value()).value();

        acc[curr.country] = {
          value,
          name: curr.name
        };
        return acc;
      }, {});

      return Object.keys(totals).map(source => ({
        code: source,
        orders: totals[source]
      }));
    },

    get mapOrdersByCountry () {
      if (!metrics.ordersByCountry) return [];
      return worldMapFormat(metrics.ordersByCountry, 'orders');
    },

    get brandInventory () {
      const groupedByCountry = groupBy(metrics.inventory, 'name');
      const all = Object.keys(groupedByCountry).map(name => ({ name, count: sumBy(groupedByCountry[name], i => parseInt(i.count)) }));
      const giftcards = metrics.inventory.filter(i => i.type === 'giftcard');
      const other = metrics.inventory.filter(i => i.type !== 'giftcard');

      return {
        byCountry: {
          giftcards,
          other,
          all
        },
        totals: {
          giftcards: sumBy(giftcards, i => parseInt(i.count)),
          all: sumBy(all, i => parseInt(i.count))
        }
      }
    }
  };

  return mutated;
};

export const mutateReferrerMetrics = metrics => {
  if (!metrics) return null;

  const mutated = {
    get orderCountByCustomer () {
      return metrics.averageOrderCountByCustomer.map(item => ({
        date: moment.utc(item.date).format(FORMAT),
        ...omit(item, 'date')
      }));
    },

    get averageOrderCountByCustomer () {
      const total = this.orderCountByCustomer.reduce((acc, curr) => acc + curr.average, 0);
      return this.orderCountByCustomer.length
        ? numeral(total).divide(this.orderCountByCustomer.length).format('0,0[.]00')
        : '0';
    },

    get averageSalesPerPeriod () {
      const countByCurrency = countBy(metrics.transactionValue, o => o.currency);
      const totals = metrics.transactionValue.reduce((acc, curr) => {
        const value = (acc[curr.currency] || 0) + curr.total;
        acc[curr.currency] = value;
        return acc;
      }, {});

      Object.keys(totals).forEach(currency => {
        totals[currency] = parseFloat(numeral(totals[currency])
          .divide(countByCurrency[currency])
          .value()
          .toFixed(2)
        );
      });

      return totals;
    },

    get spendByCustomer () {
      return mergeByCurrency(metrics.averageOrderSizeByCustomer, 'averageAmount');
    },

    get averageSpendByCustomer () {
      const byCurrency = groupBy(metrics.averageOrderSizeByCustomer, o => o.currency);
      const totals = metrics.averageOrderSizeByCustomer.reduce((acc, curr) => {
        const value = (acc[curr.currency] || 0) + curr.averageAmount;
        acc[curr.currency] = value;
        return acc;
      }, {});

      Object.keys(totals).forEach(currency => {
        totals[currency] = byCurrency[currency].length >= 1
          ? parseFloat(numeral(totals[currency])
            .divide(byCurrency[currency].length)
            .value()
            .toFixed(2)
          )
          : 0;
      });

      return totals;
    },

    get ordersByCurrency () {
      return mergeByCurrency(metrics.ordersByCurrency, 'orders');
    },

    get totalOrdersByCurrency () {
      return sumTotals(metrics.ordersByCurrency, 'currency', 'orders');
    },

    get spendByCurrency () {
      return mergeByCurrency(metrics.ordersByCurrency, 'spend');
    },

    get topBrands () {
      return groupBy(metrics.topBrands, brand => brand.currency);
    },

    get totalSpendByCurrency () {
      return sumTotals(metrics.ordersByCurrency, 'currency', 'spend');
    },

    get transactionValue () {
      return mergeByCurrency(metrics.transactionValue, 'total');
    },

    get totalTransactionValue () {
      const totals = metrics.transactionValue.reduce((acc, curr) => {
        const value = numeral((acc[curr.currency] || 0)).add(curr.total).value();
        acc[curr.currency] = value;
        return acc;
      }, {});

      return Object.keys(totals).map(currency => ({
        name: currency,
        amount: totals[currency]
      }));
    }
  }

  return mutated;
};
