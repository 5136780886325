import { observable, reaction } from 'mobx';
import { hasLoader } from '@/utils';

export default class BrandsStore {
  @observable providers = [];
  @observable countries = [];
  @observable categories = [];

  types = [
    'bundle',
    'data',
    'digitaltv',
    'giftcard',
    'longdistance',
    'topup',
    'pin',
    'esim'
  ];

  constructor ({ app, client }) {
    this.app = app;
    this.client = client;

    reaction(() => app.isAuthenticated, () => {
      if (app.isAuthenticated) {
        this.findProviders();
        this.findCountries();
        this.findCategories();
      }
    });
  }

  @hasLoader()
  async patchBrand (id, payload) {
    return this.client.service('brands').patch(id, payload);
  }

  @hasLoader()
  async findProviders () {
    const { data } = await this.client.service('providers').find();
    this.providers = data;
  }

  @hasLoader()
  async findCountries () {
    this.countries = await this.client.service('countries').find();
  }

  @hasLoader()
  async findCategories () {
    const query = { $sort: { name: 1 } };
    const { data } = await this.client.service('categories').find({ query });
    this.categories = data;
  }

  async addToCategory (brandId, categoryId) {
    const result = await this.client.service('brands-categories').create({ brandId, categoryId });
    return result;
  }

  async removeFromCategory (brandId, categoryId) {
    const query = { brandId, categoryId };
    await this.client.service('brands-categories').remove(null, { query });
  }

  async patchProduct (id, payload) {
    return this.client.service('products').patch(id, payload);
  }
}
