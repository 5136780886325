import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { Icon, Spin } from 'antd';
import styled from 'styled-components';
import { Provider as Figbird } from 'figbird';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'normalize.css/normalize.css';
import 'antd/dist/antd.less';

import feathersClient from '@/feathers-client';
import Login from '@/containers/login';
import App from '@/app';
import Store from '@/app/store';
import MetricsStore from '@/containers/metrics/store';
import BrandsStore from '@/containers/brands/store';
import UIStore from '@/app/ui-store';
import config from '@/config';
import * as serviceWorker from '@/serviceWorker';
import '@/ant-overrides.less';

const client = feathersClient(config.apiURL);
const blockchainClient = feathersClient(config.blockchainApiURL);
const appStore = new Store(client);
const uiStore = new UIStore();
const storeOptions = { app: appStore, client, uiStore, blockchainClient };
const metricsStore = new MetricsStore(storeOptions);
const brandsStore = new BrandsStore(storeOptions);

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const Spinner = styled(Icon).attrs({
  type: 'loading',
  spin: true
})`
  font-size: 2em;
`;

Spin.setDefaultIndicator(<Spinner />);

const Main = observer(() => {
  if (appStore.authenticateStatus && appStore.authenticateStatus.isLoading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Figbird feathers={client}>
      <Provider
        app={appStore}
        brandsStore={brandsStore}
        client={client}
        metricsStore={metricsStore}
        blockchainClient={blockchainClient}
        uiStore={uiStore}>
        <HashRouter>
          <Switch>
            {appStore.isAuthenticated && <App />}
            {!appStore.isAuthenticated && <Login />}
          </Switch>
        </HashRouter>
      </Provider>
    </Figbird>
  );
});

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
