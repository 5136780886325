import currencies from './currencies.json';
import networks from './networks.json';

const isProduction = process.env.REACT_APP_PAYMENTS_API_URL === 'https://api.bidali.com';

export default {
  apiURL: process.env.REACT_APP_API_URL,
  paymentsApiURL: process.env.REACT_APP_PAYMENTS_API_URL,
  paymentsApiKey: process.env.REACT_APP_PAYMENTS_API_KEY,
  blockchainApiURL: 'https://blockchain.bidali.com',
  dashboardURL: process.env.REACT_APP_DASHBOARD_URL,
  isProduction,
  currencies,
  networks
};
