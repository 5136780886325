import React, { useEffect, useState } from 'react';
import { Button, Layout, Icon } from 'antd';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { useMedia } from 'react-use';

import { colors, media, shadows } from '@/theme';
import glyph from '@/assets/bidali-glyph.svg';
import Routes from '@/routes';
import Menu from '@/components/menu';

const Container = styled(Layout)`
  background: ${colors.background.primary};
  min-height: 100vh;
  overflow-x: hidden;
`;

const Header = styled(Layout.Header)`
  align-items: center;
  background: white;
  box-shadow: ${shadows.primary};
  display: flex;
  padding: 0 1em;
  position: sticky;
  top: 0;
  z-index: 2;

  /* @supports (backdrop-filter: blur(5px)) {
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(5px);
  } */
`;

const AppTitle = styled.span`
  color: ${colors.typography.primary}aa;
  line-height: 1;
  padding: .5em;
  width: 10.75em;
`;

const Sider = styled(Layout.Sider)`
  background: rgba(0, 0, 0, .025);
`;

const Content = styled(Layout.Content)`
  background: none;
  padding: 2em;

  ${media.sm} {
    min-width: 100%;
    padding: 1em;
  }
`;

const Hamburger = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;

  ${media.sm} {
    display: block;
  }
`;

const Logo = styled.img.attrs({ src: glyph })`
  display: inline-block;
  height: 2.5em;
  vertical-align: middle;
  width: auto;
`;

const FilterToggle = styled(Hamburger)`
  margin-left: auto;
`;

const SignOut = styled(Button).attrs({ type: 'flat' })`
  line-height: 1;
  padding: 0 .65em;
`;

const ToolbarContainer = styled.div`
  flex-grow: 1;

  ${({ $showToolbar }) => css`
    ${media.sm} {
      background-color: white;
      box-shadow: ${shadows.primary};
      display: ${$showToolbar ? 'flex' : 'none'};
      left: -2em;
      overflow-x: auto;
      padding: 1em;
      position: absolute;
      top: 4.5em;
      width: calc(100% + 2em);
    }
  `}
`;

const Toolbar = styled.div.attrs({ id: 'main-toolbar' })`
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin: 0 2em;

  > * + * {
    margin-left: 1em;
  }

  ${media.sm} {
    align-items: stretch;
    flex-direction: column;

    > * + * {
      margin-left: 0;
      margin-top: 1em;
    }
  }
`;

const App = ({ app, history, location }) => {
  const isMobile = useMedia('(max-width: 480px)', true);
  const [ collapsed, setCollapsed ] = useState(isMobile ? true : false);
  const [ showToolbar, setShowToolbar ] = useState(false);

  const handleMenuClick = ({ keyPath }) => {
    if (keyPath) {
      history.push(`/${keyPath.join('/')}`);
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      history.replace('/metrics');
    }

    if (isMobile) {
      setCollapsed(true);
    }
  }, [ history, location ]);

  return (
    <Container>
      <Header>
        <Hamburger onClick={() => setCollapsed(!collapsed)}>
          <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
        </Hamburger>
        <Link to="/metrics">
          <Logo />
        </Link>
        <AppTitle>Commerce<br />Dashboard</AppTitle>
        <ToolbarContainer $showToolbar={showToolbar}>
          <Toolbar />
        </ToolbarContainer>
        <FilterToggle onClick={() => setShowToolbar(!showToolbar)}>
          <Icon type="filter" theme={showToolbar ? 'filled' : 'outlined'} />
        </FilterToggle>
        <SignOut onClick={app.signOut}>
          <Icon type="logout" />
        </SignOut>
      </Header>
      <Layout style={{ background: 'none' }}>
        <Sider
          collapsed={isMobile && collapsed}
          collapsedWidth={isMobile ? 0 : undefined}
          trigger={null}>
          <Menu
            onClick={handleMenuClick}
            onToggle={() => setCollapsed(!collapsed)}
            collapsed={collapsed} />
        </Sider>
        <Content>
          <Routes />
        </Content>
      </Layout>
    </Container>
  );
};

export default inject('app')(withRouter(App));
