import { action, observable, computed } from 'mobx';
import moment from 'moment';
import axios from 'axios';
import config from '@/config';
import { hasLoader } from '@/utils';

export default class AppStore {
  @observable user = null;
  @observable markups = null;
  @observable rates = null;

  constructor (feathersClient) {
    this.client = feathersClient;
    this.authenticate();
    // Go to login when theres a 401 error
    this.client.hooks({
      error: [async context => {
        const { code } = context.error;

        if (code === 401) {
          this.clearUser();
        }

        return context;
      }]
    });
  }

  @computed get isAuthenticated () {
    return !!this.user;
  }

  async fetchMarkups() {
    const { data: markups } = await axios.get(`${config.paymentsApiURL}/markups`, { params: { organization: config.paymentsApiKey } });
    this.markups = markups;
  }

  async fetchRates() {
    const { data: { rates } } = await axios.get(`${config.paymentsApiURL}/rates`, { params: { organization: config.paymentsApiKey } });
    this.rates = rates;
  }

  @hasLoader()
  async signIn (credentials) {
    const payload = credentials.strategy ? credentials : {
      ...credentials, strategy: 'local'
    };
    const { user } = await this.client.authenticate(payload);    
    this.setUser(user);
    await this.fetchMarkups();
    await this.fetchRates();
  }

  @hasLoader()
  async authenticate () {
    try {
      const { accessToken, user, authentication: { payload } } = await this.client.reAuthenticate();
      const expiryDate = moment.unix(payload.exp);

      this.setUser(user);

      if (expiryDate.diff(moment(), 'hours') <= 2) {
        this.signIn({ accessToken, strategy: 'jwt' });
      }
      await this.fetchMarkups();
      await this.fetchRates();
    } catch (e) {
      const knownErrors = [
        'jwt expired',
        'No accessToken found in storage'
      ];

      if (!knownErrors.includes(e.message)) {
        throw e;
      }
    }
  }

  signOut = async () => {
    await this.client.logout();
    this.clearUser();
  }

  @action
  setUser (user) {
    this.user = user;
  }

  @action
  clearUser () {
    this.user = null;
  }
}
