import { observable } from 'mobx';
import moment from 'moment';

export default class UIStore {
  @observable brands = {
    pagination: {},
    query: {
      $search: undefined,
      countryId: undefined,
      providerId: undefined,
      type: undefined,
      categories: observable([]),
      discontinued: 'false',
      enabled: 'true'
    }
  };

  @observable countries = {
    $search: undefined,
    enabled: undefined,
    availableInWallet: undefined
  };

  @observable referrers = {
    expandedKeys: [],
    pagination: {},
    query: {
      email: undefined,
      $search: undefined,
      active: 'true'
    }
  };

  @observable referrer = {
    preset: 'From start of month',
    query: {
      period: 'day',
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    }
  };

  @observable metrics = {
    preset: 'From start of month',
    query: {
      period: 'day',
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    }
  };

  @observable customer = {
    query: { $skip: 0 },
    pagination: {}
  };

  @observable customers = {
    pagination: {},
    query: {
      $search: undefined
    }
  };

  @observable orders = {
    pagination: {},
    query: {
      status: { $nin: ['timeout'], $ne: null },
      undeliverable: { $ne : true },
      chargeId: { $ne: null },
      paymentCurrency: { $ne: null },
      emailedAt: undefined,
      customerId: undefined
    }
  };

  @observable defaultValueForCustomerId = undefined;
}
